html {
  min-height: 100vh;
}
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background: url(/assets/home-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
